import React, { useEffect, useState } from 'react'
import '../styles/Navbar.css'
// import {Link} from 'react-router-dom'
import gsap from 'gsap'
import { useTranslation } from 'react-i18next'
function Navbar() {
  const [isSidebarActive, setIsSidebarActive] = useState(false)
  const [imgIndex, setImgIndex] = useState(-1)
  const { i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState("العربية")
  
  function setLocalStorage(value){
    localStorage.setItem('language', value)
  }
  const languages = {
    ar:"العربية",
    en:"English",
    fa:"فارسى",
    ur:"اردو",
  }
  useEffect(() => {
    const language = localStorage.getItem("language")
    if(language){
      setCurrentLanguage(languages[language])
    }else {
      setCurrentLanguage('العربية')
    }
  })
  function toggleSidebar(state){
    if(state){
      gsap.to("header nav", {
        x:0,
        duration:1.5,
        overwrite:true,
        ease:'expo.out'
      })
    }else {
      gsap.to("header nav", {
        x:'-150%',
        duration:1,
        overwrite:true,
        ease:'expo.inOut'
      })
    }
  }
  useEffect(() => {
    toggleSidebar(isSidebarActive)
  }, [isSidebarActive])
  return (
    <header dir='rtl'>
      <div className={isSidebarActive ? 'blur-container active' : "blur-container"} onClick={() => setIsSidebarActive(!isSidebarActive)}></div>
      <div className='header-content'>
        <div className='container'>
          <div className='logo'>
            <img alt='Royal Hotel Logo' loading='lazy' src={require('../assets/images/logo.webp')} />
          </div>
          <div className='sidebar-btn-container language-relative-container'>
            <div className={isSidebarActive ? 'sidebar-btn active' : 'sidebar-btn'} onClick={() => {
              // setIsSidebarActive(!isSidebarActive)
              
              }}>
              {/* <div className='line'></div>
              <div className='line'></div>
              <div className='line'></div> */}
              <div>{currentLanguage}</div>
              <div className='language-dropdown'>
                <ul>
                  <li onClick={() => {
                    setCurrentLanguage("العربية")
                    i18n.changeLanguage("ar")
                    setLocalStorage('ar')
                    window.location.reload()
                  }}>العربية</li>
                  <li onClick={() => {
                    setCurrentLanguage("English")
                    i18n.changeLanguage("en")
                    setLocalStorage('en')
                    window.location.reload()

                  }}>English</li>
                  <li onClick={() =>{
                    setCurrentLanguage("فارسى")
                    i18n.changeLanguage("fa")
                    setLocalStorage('fa')
                    window.location.reload()

                  }}>فارسى</li>
                  <li onClick={() => {
                    setCurrentLanguage("اردو")
                    i18n.changeLanguage("ur")
                    setLocalStorage('ur')
                    window.location.reload()

                  }}>اردو</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <nav>
        <ul>
          <li onMouseOver={() => {setImgIndex(0)}}>
            <Link to='/'>
              <div className='link-content'>
                <div>حولنا</div>
                <div>حولنا</div>
              </div>
            </Link>
          </li>
          <li onMouseOver={() => {setImgIndex(1)}}>
            <Link to='/menu'>
              <div className='link-content'>
                <div>المنيو الالكتروني</div>
                <div>المنيو الالكتروني</div>
              </div>
            </Link>
          </li>
          <li onMouseOver={() => {setImgIndex(2)}}>
            <Link to='/book'>
              <div className='link-content'>
                <div>حجز</div>
                <div>حجز</div>
              </div>
            </Link>
          </li>
        </ul>
        <div className='image'>
          <img src={require('../assets/images/hotel image 1.webp')} alt='navbar-image' className={imgIndex === 0 ? 'active' : ''} loading='lazy' />
          <img src={require('../assets/images/menu.webp')} alt='navbar-image' className={imgIndex === 1 ? 'active' : ''} loading='lazy' />
          <img src={require('../assets/images/book.webp')} alt='navbar-image' className={imgIndex === 2 ? 'active' : ''} loading='lazy' />
        </div>
      </nav> */}
    </header>
  )
}

export default Navbar